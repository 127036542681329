import styled from 'styled-components'

import { HelpLinks } from '@/constants'
import { Button } from '@atoms/Button'
import { Link } from '@atoms/Link'
import { Typography } from '@atoms/Typography'
import { Panel } from '@organisms/Panel'
import { BasicLayout } from '@layouts/Basic'

export type Props = {
  readonly onClick: () => void
}

export const Presentation = ({ onClick }: Props) => (
  <BasicLayout>
    <BasicLayout.Header />
    <BasicLayout.Body>
      <StyledPanel title="お探しのページが見つかりません">
        <Typography>
          <p>このページは削除されたか、別の場所へ移動した可能性があります。</p>
        </Typography>
        <ButtonArea>
          <Button onClick={onClick}>ログイン画面に戻る</Button>
        </ButtonArea>
      </StyledPanel>
    </BasicLayout.Body>
    <BasicLayout.Footer>
      <Link href={HelpLinks.chatSupport} iconName="help_outline" blank>
        お困りの場合はこちら
      </Link>
    </BasicLayout.Footer>
  </BasicLayout>
)

const StyledPanel = styled(Panel)`
  padding: 48px 80px;

  &.panel {
    & > .title {
      margin-bottom: 24px;
    }
  }
`

const ButtonArea = styled.div`
  margin-top: 24px;
`
