import Head from 'next/head'
import { NotFound } from '@organisms/NotFound'

export default function NotFoundPage() {
  return (
    <>
      <Head>
        <title>お探しのページが見つかりません | bellface</title>
      </Head>
      <NotFound />
    </>
  )
}
