import { ReactNode } from 'react'
import styled from 'styled-components'

import { Logo } from '@atoms/Logo'
import { Link } from '@atoms/Link'

export type Props = {
  readonly children: ReactNode
}

export type HeaderProps = {
  readonly disabled?: boolean
}

export type BodyProps = {
  readonly children?: ReactNode
}

export type FooterProps = {
  readonly disabled?: boolean
  readonly children?: ReactNode
}

const BasicLayout = ({ children }: Props) => <StyledBasicLayout>{children}</StyledBasicLayout>

const Header = ({ disabled = false }: HeaderProps) => (
  <>
    {!disabled && (
      <StyledHeader>
        <Logo />
      </StyledHeader>
    )}
  </>
)

const Body = ({ children }: BodyProps) => <StyledBody>{children}</StyledBody>

const Footer = ({ disabled = false, children }: FooterProps) => (
  <>
    {!disabled && (
      <StyledFooter>
        {children ? (
          children
        ) : (
          <Link
            href="https://faq.bell-face.com/%E3%83%AD%E3%82%B0%E3%82%A4%E3%83%B3%E3%83%91%E3%82%B9%E3%83%AF%E3%83%BC%E3%83%89%E3%81%AE%E5%A4%89%E6%9B%B4-5e65b2684a7a7c00170ad026"
            iconName="help_outline"
            blank
          >
            ご利用方法はこちら
          </Link>
        )}
      </StyledFooter>
    )}
  </>
)

BasicLayout.Header = Header
BasicLayout.Body = Body
BasicLayout.Footer = Footer

export { BasicLayout }

const StyledBasicLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledHeader = styled.header`
  & > div {
    vertical-align: bottom;
  }
`

const StyledBody = styled.main`
  width: 480px;

  & > .panel {
    & > .title {
      margin-bottom: 32px;
    }
  }
`

const StyledFooter = styled.footer`
  margin-top: 24px;
  font-size: 16px;
`
